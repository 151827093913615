import React, { useState } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TextField,
  Button,
} from '@mui/material'
import { useIntl, FormattedMessage } from 'react-intl'
import useAuth from '@hooks/useAuth'
import { navigate } from 'gatsby'

const inputStyle = { WebkitBoxShadow: '0 0 0 0 red inset' }

type LoginFormValuesType = {
  name: string
  password: string
}

export default function LoginCard() {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const { LoggingIn } = useAuth()

  const initialValues: LoginFormValuesType = {
    name: '',
    password: '',
  }

  const validationSchema = Yup.object({
    name: Yup.string().required('Name wird benötigt.'),
    password: Yup.string().required('Passwort wird benötigt.'),
  })

  function onSubmitClick(
    values: LoginFormValuesType,
    actions: FormikHelpers<LoginFormValuesType>
  ): void {
    setLoading(true)
    LoggingIn(values.name, values.password).then(() => {
      actions.setSubmitting(false)
      navigate('/', { replace: true })
    })
  }

  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: 'login.title' })} />
      <Divider />
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmitClick}
        >
          {({
            isSubmitting,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }) => (
            <Form>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    id="name-input"
                    data-testid="mail-input"
                    fullWidth
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    type="name"
                    name="name"
                    label="Name"
                    helperText={
                      touched.name && errors.name ? errors.name : null
                    }
                    error={!!errors.name}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="pw-input"
                    data-testid="pw-input"
                    fullWidth
                    variant="outlined"
                    inputProps={{ style: inputStyle }}
                    name="password"
                    label="Passwort"
                    type="password"
                    helperText={
                      touched.password && errors.password
                        ? errors.password
                        : null
                    }
                    error={!!errors.password}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <Button
                    type="submit"
                    fullWidth
                    color="primary"
                    variant="contained"
                    size="large"
                    disabled={loading || isSubmitting}
                  >
                    <FormattedMessage id="button.login" />
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
